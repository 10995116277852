<template lang="pug">
.container
  b-loading(:is-full-page='false' v-model='isLoading')
  page-title(title='Reglas de zonas')
  .card
    .card-content
      app-form(@submit='submit' :is-saving='isSaving' :horizontal='false')
        rule-base.mb-3(v-model='ruleBase')
        .card.mb-3
          .card-content
            .zones-container
              .zone-item(v-for='zone in zones')
                span {{ zone.name }}
                b-checkbox(v-model='zonesStatus[zone.id].onEnter') Al ingresar
                b-checkbox(v-model='zonesStatus[zone.id].onExit') Al salir
        rule-output.mb-3(v-model='ruleOutputs')
</template>

<script>
import { ruleTypeEnum } from '@/constants/enums'
import toastService from '@/services/toast.service'
import zonesService from '@/services/zones.service'
import rulesService from '@/services/rules.service'

import RuleBase from './blocks/RuleBase'
import RuleOutput from './blocks/RuleOutput'
import { rulesList } from '@/router/routes'

export default {
  components: { RuleBase, RuleOutput },
  data () {
    return {
      ruleBase: {
        type: ruleTypeEnum.Zone,
        name: '',
        devices: []
      },
      ruleOutputs: {},
      zonesStatus: {},
      zones: [],
      isLoading: false,
      isSaving: false
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      this.isLoading = true

      const promises = [zonesService.getAll()]
      if (this.$route.params.id) {
        promises.push(rulesService.getOne(this.$route.params.id))
      }
      const [zonesResult, ruleResult] = await Promise.all(promises)
      if (zonesResult) {
        this.zonesStatus = zonesResult.reduce((memory, { id }) => {
          const zone = (ruleResult?.zoneRules || []).find(zone => zone.zoneId === id) || {}
          memory[id] = { zoneId: id, onExit: false, onEnter: false, ...zone }
          return memory
        }, {})
        this.zones = zonesResult
      }
      if (ruleResult) {
        const { id, type, name, devices, emailActions, turnOffEngine, generateEvent } = ruleResult
        this.ruleBase = { id, type, name, devices }
        this.ruleOutputs = { emailActions, generateEvent, turnOffEngine }
      }
      this.isLoading = false
    },
    getRoute () {

    },
    async submit () {
      const zoneRules = this.zones.map(zone => this.zonesStatus[zone.id]).filter(zone => zone.onEnter || zone.onExit)
      const rule = {
        ...this.ruleBase,
        ...this.ruleOutputs,
        zoneRules
      }
      this.isSaving = true
      const result = await rulesService.save(rule)
      if (result) {
        toastService.show('Regla guardada')
        this.$router.push({ name: rulesList.name })
      }
      this.isSaving = false
    }
  }
}
</script>

<style scoped>
.zones-container {
  max-height: 200px;
  overflow-y: auto;
}

.zone-item {
  display: grid;
  grid-template-columns: 1fr 130px 130px;
}
</style>
