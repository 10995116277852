export default (fn, delay, updatesFn) => {
  var timeoutID = null
  return function () {
    if (updatesFn) updatesFn(true)
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(() => {
      const result = fn.apply(that, args)
      if (updatesFn) {
        if (result instanceof Promise) {
          result.then(() => {
            updatesFn(false)
          })
        } else updatesFn(false)
      }
    }, delay)
  }
}
