<template lang="pug">
b-field(:label='label' label-position='on-border')
  b-taginput(
    v-model='rawValue'
    autocomplete
    field='deviceName'
    :placeholder='placeholder'
    :data='filteredDevices'
    :loading='filtering'
    :required='required'
    @typing='onInputTyping'
    @input='valueChanged'
  )
</template>

<script>
import devicesService from '@/services/devices.service'
import debounce from '@/utils/debounce'

export default {
  props: {
    label: { type: String, default: 'Listado de equipos' },
    // icon: { type: String },
    // type: { type: String, default: 'text' },
    required: { type: Boolean },
    // disabled: { type: Boolean },
    // readonly: { type: Boolean },
    placeholder: { type: String, default: 'Agrega un equipo' },
    maxlength: { type: Number },
    value: {}
  },
  data () {
    return {
      filteredDevices: [],
      filtering: false,
      rawValue: this.value,
      filterDevicesDebounced: debounce(this.filterDevices.bind(this), 1000, this.filterDevicesStatus.bind(this))
    }
  },
  watch: {
    value () {
      this.rawValue = this.value
    }
  },
  methods: {
    valueChanged () {
      this.$emit('input', this.rawValue)
    },
    onInputTyping (event) {
      if (event) {
        this.filterDevicesDebounced(event)
      } else {
        this.filteredDevices = []
      }
    },
    filterDevicesStatus (isLoading) {
      this.filtering = isLoading
    },
    async filterDevices (q) {
      const result = await devicesService.getAll({ q })
      if (result) {
        this.filteredDevices = result.map(device => {
          return {
            deviceId: device.id,
            deviceName: device.name
          }
        })
      }
    }
  }
}
</script>
