<template lang="pug">
.card
  .card-content
    text-field(label='Nombre de la regla' :placeholder='namePlaceholder' :maxlength='30' required v-model='rawValue.name' @input='onInput')
    devices-field(v-model='rawValue.devices' @input='onInput' label='Equipos para las cuales aplica esta regla')
</template>

<script>
import DevicesField from '@/components/shared/forms/DevicesField'

export default {
  components: { DevicesField },
  props: {
    namePlaceholder: { type: String },
    value: { type: Object }
  },
  data () {
    return {
      sendEmail: false,
      rawValue: this.value
    }
  },
  watch: {
    value () {
      this.rawValue = this.value
    }
  },
  methods: {
    onInput () {
      this.$emit('input', this.rawValue)
    }
  }
}
</script>
